<template>
  <div class="card">
    <div class="card-body">
      <div class="float-end">
        <a-tag v-if="publication.deactivatedAt" color="orange">Scheduled Deletion</a-tag>
      </div>
      <h2 class="h6">{{ publication.name }}</h2>
      <div class="text-muted">
        {{ publication.role.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    publication: Object,
  },
}
</script>
