<template>
  <slot />
  <a-modal
    title="New Publication"
    :closable="false"
    :confirmLoading="loading"
    :maskClosable="false"
    :okButtonProps="{ htmlType: 'submit' }"
    okText="Confirm"
    :visible="visible"
    @cancel="$emit('cancel')"
    @ok="onFinish"
  >
    <a-form
      :colon="false"
      layout="vertical"
      :model="values"
      ref="form"
      @finish="onFinish"
    >
      <a-form-item
        label="Name of Publication"
        name="name"
        :rules="[
          { required: true, message: 'Name is required.' },
        ]"
      >
        <a-input
          v-model:value="values.name"
          :maxlength="32"
          name="name"
        />
      </a-form-item>
      <a-form-item
        label="Subdomain"
        name="subdomain"
        :rules="[
          { required: true, message: 'Subdomain is required.' },
          { min: 5, message: 'Subdomain must be at least 5 characters.' },
          { validator: checkSubdomain },
        ]"
      >
        <a-input
          v-model:value="values.subdomain"
          addon-after=".postodian.com"
          :maxlength="30"
          name="subdomain"
        />
      </a-form-item>
      <a-form-item
        label="Default Language"
        name="language"
        :rules="[
          { required: true, message: 'Language is required.' },
        ]"
      >
        <a-select
          v-model:value="values.language"
          name="language"
          showSearch
          placeholder="Select a language"
        >
          <a-select-option v-for="(name, value) in $store.state.languages" :value="value">
            {{ name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="Description"
        name="description"
      >
        <a-textarea
          v-model:value="values.description"
          :maxlength="250"
          name="description"
          placeholder="Journalistic masterpieces start here."
          :rows="4"
          showCount
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      values: {
        name: null,
        subdomain: null,
        language: window.navigator.language.slice(0,2),
        description: null,
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        var { data } = await this.$http.post(`/publications`, values)

        this.$message.success('Publication has been saved.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
    async checkSubdomain() {
      try {
        var subdomain = this.values.subdomain.trim()

        if (!subdomain || subdomain.length < 5 || subdomain.length > 30) {
          return false
        }

        var { data } = await this.$http.get(`/subdomains/${ subdomain }`)

        if (!data.available) {
          return Promise.reject('Subdomain is not available.')
        }
      } catch (err) {
        return Promise.reject('Couldn’t check availability.')
      }
    },
  },
}
</script>
