<template>
  <div class="container">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h1 class="h3 mb-0">Your Publications <small v-if="publicationsCount !== null" class="text-muted">({{ publicationsCount }})</small></h1>
      <PublicationCreate
        :visible="visible"
        @cancel="visible = false"
        @success="onCreate"
      >
        <a-button type="primary" shape="circle" size="large" :disabled="loading" @click="visible = true">
          <PlusIcon class="icon" />
        </a-button>
      </PublicationCreate>
    </div>
    <BaseSpinner :loading="loading">
      <div class="row">
        <div v-for="publication in publications" class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link :to="{ name: 'publications-id', params: { publication: publication.id }}">
            <PublicationItem :publication="publication" />
          </router-link>
        </div>
      </div>
    </BaseSpinner>
  </div>
</template>

<script>
import { PlusIcon } from '@heroicons/vue/24/outline'
import BaseSpinner from '@/components/Base/BaseSpinner'
import PublicationCreate from '@/components/Publication/PublicationCreate'
import PublicationItem from '@/components/Publication/PublicationItem'

export default {
  components: {
    BaseSpinner,
    PlusIcon,
    PublicationCreate,
    PublicationItem,
  },

  data() {
    return {
      visible: false,
      loading: false,
      publications: [],
      publicationsCount: null,
    }
  },

  async mounted() {
    await this.listPublications()
  },

  methods: {
    async listPublications() {
      try {
        this.loading = true

        var { data } = await this.$http.get(`/publications`)

        this.publications = data.publications
        this.publicationsCount = data.publicationsCount
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loading = false
      }
    },
    onCreate(data) {
      this.visible = false
      this.$router.push({ name: 'publications-id', params: { publication: data.id }})
    },
  },
}
</script>
